<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('companies', 'create')" type="is-primary create" rounded @click="create($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('buttons.add') }}</span>
			</b-button>
		</Header>
		<filter-default enable-search @filterSearch="filterSearch" />
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="companies.length == 0 && !loading" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-4-widescreen">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline" tag="div">
			<div v-for="r in companies" :key="r.id" class="list-item column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block" @click.self="update(r.id)">
					<div class="block__content" @click="update(r.id)">
						<h3 class="block__name" translate="no">{{ r.name }}</h3>
						<p class="block__email">{{ format(r.created_at) }} • {{ timeTo(r.created_at) }}</p>
					</div>
					<Trigger :id="r.id" />
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Role'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Trigger from '@/components/triggers/Company'
import Api from '@/services/api'
import { responsive } from '@/mixins/responsive'
import Modal from '@/components/modals/Company'
import eventHub from '@/services/eventHub'
import { create, update } from '@/mixins/modal'
import { mapGetters, mapState } from 'vuex'
import { successToast, errorToast } from '@/mixins/toast'
import FilterDefault from '@/components/FilterDefault'

export default {
	name: 'Company',
	mixins: [responsive],
	components: {
		Layout,
		Header,
		Error,
		Results,
		Trigger,
		'svg-icon': Icon,
		Placeholder,
		FilterDefault
	},
	data() {
		return {
			pagination: 12,
			loading: true,
			errored: false,
			order: 1,
			companies: []
		}
	},
    mounted() {
        this.findAll()

		eventHub.$on('edit-modal-companies', obj => {
			update(this, 'companies', obj.id, Modal, 'Edit')
		})

		eventHub.$on('reload-companies', () => {
			this.findAll()
		})

		eventHub.$on('delete-companies', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('labels.warning'),
				message: '<span>' + this.$t('alerts.delete.confirmation') + '<span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('buttons.no'),
				confirmText: this.$t('buttons.yes'),
				onConfirm: async () => {
					try {
						const response = await Api.delete(`company/destroy/${obj.id}`)

						if (response.status === 200) {
							successToast(this.$t('alerts.delete.success_f', [this.$tc('menu.company')]))
							await this.findAll()
						} else {
							errorToast(this.$t('alerts.delete.error_f', [this.$tc('menu.company')]))
						}
					} catch (e) {
						console.log(e)
					}
				}
			})
		})
    },
	methods: {
		update(id) {
			if (this.permissionEnabled('companies', 'edit')) {
				update(this, 'companies', id, Modal, 'Edit')
			}
		},
		create() {
			if (this.permissionEnabled('companies', 'create')) {
				create(this, 'companies', Modal, 'New')
			}
		},
        async findAll() {
			try {
				this.companies = []
				this.loading = true
				const response = await Api.get('company/findAll')

				if (response.status === 200) {
					this.companies = response.data
				}
			} catch (e) {
				console.log(e)
				errorToast('Ocorreu um erro ao listar')
			} finally {
				this.loading = false
			}
        },
		async filterSearch(v) {
			try {
				this.companies = []
				this.loading = true
				if (v.length > 1) {
					const response = await Api.post('company/findByName', {
						name: v
					})

					if (response.status === 200) {
						this.loading = false
						this.companies = response.data
					}
				} else {
					await this.findAll()
				}
			} catch (e) {
				console.log(e)
				this.loading = false
			}
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled']),
		...mapState('user', ['user'])
	},
	destroyed() {
		eventHub.$off('edit-modal-companies')
		eventHub.$off('delete-companies')
		eventHub.$off('reload-companies')
	}
}
</script>
