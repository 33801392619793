<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(save)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name, true)) }} <strong>{{ $tc('menu.company') }}</strong></span>
					<span v-if="company">#{{ company.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">

					<div class="columns">
						<div class="column">
							<InputWithValidation name="name" rules="required|min:2" type="text" :label="$tc('fields.name') + ` *`" v-model="company.name" />
						</div>
					</div>

					<small class="modal-updated" v-if="company.updated_at">{{ $t('last_change') }} {{ format(company.updated_at) }}</small>
				</div>
			</div>
			<Footer :loading="loading" />
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import Footer from '@/components/modals/Footer'
import '@/mixins/generic'

export default {
	components: {
		Footer,
		InputWithValidation,
		ValidationObserver
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		},
		type: {
			type: [String, Number],
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			company: {
				name: ''
			}
		}
	},
	methods: {
		async update() {
			try {
				this.loading = true
				const response = await Api.put(`company/update/${this.id}`, { name: this.company.name })

				if (response.status === 200) {
					this.$emit('close')
					successToast(this.$t('alerts.update.success_f', [this.$tc('menu.company')]))
					eventHub.$emit('reload-companies')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(message)
				} else {
					errorToast(this.$t('alerts.update.error_f', [this.$tc('menu.company')]))
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				try {
					this.isOpening = true
					const response = await Api.get(`company/findById/${this.id}`)

					if (response.status === 200) {
						this.company = response.data
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.post('company/store', { name: this.company.name })

				if (response.status == 201) {
					this.$emit('close')
					successToast(this.$t('alerts.create.success_f', [this.$tc('menu.company')]))
					eventHub.$emit('reload-companies')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(message)
				} else {
					errorToast(this.$t('alerts.create.error_f', [this.$tc('menu.company')]))
				}
			} finally {
				this.loading = false
			}
		},
		async save() {
			this.name === 'New' ? await this.store() : await this.update()
		}
	},
	mounted() {
		this.findById()
	}
}
</script>
